import React from 'react';
import { Badge, Card, Col, Container, Form, Row } from 'react-bootstrap';

function Contact() {
    return (
        <>
            <Card id="contact" className="contact-area padding-bottom-120 padding-top-110">
                <div className="bg-image" data-parallax="{&quot;x&quot;: -50, &quot;y&quot;: 0}" style={{ backgroundImage: 'url(assets/img/contact/01.png)' }} />
                <div className="bg-image-02" data-parallax="{&quot;x&quot;: 50, &quot;y&quot;: 0}" style={{ backgroundImage: 'url(assets/img/contact/02.png)' }} />
                <Container>
                    <Row>
                        <Col lg={{ span: 4, offset: 3 }}>
                            <Card.Title className="section-title padding-bottom-25">
                                <Badge className="sub-title" bg="inherit">Contact Us</Badge>
                                <h4 className="title">Then <Badge bg="inherit">book your trip</Badge> from our exclusive <Badge bg="inherit">offers</Badge>. </h4>
                            </Card.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ span: 3, offset: 3 }} md={4}>
                            <Card.Body className='content-area'>
                                <Row className="single-contact-item">
                                    <Col lg={2} className="icon">
                                        <i className="flaticon-call" />
                                    </Col>
                                    <Col lg={10} className="content">
                                        <Card.Text className="details">+458 123 657 <br /> +415 103 557</Card.Text>
                                    </Col>
                                </Row>
                                <Row className="single-contact-item">
                                    <Col lg={2} className="icon">
                                        <i className="flaticon-open" />
                                    </Col>
                                    <Col lg={10} className="content">
                                        <Card.Text className="details">
                                            <Card.Link href="info@digi.com">info@digi.com</Card.Link><br />
                                            <Card.Link href="info@digi.com">info@digi.com</Card.Link>
                                        </Card.Text>
                                    </Col>
                                </Row>
                                <Row className="single-contact-item">
                                    <Col lg={2} className="icon">
                                        <i className="flaticon-placeholder" />
                                    </Col>
                                    <Col lg={10} className="content" style={{ alignSelf: 'center' }}>
                                        <Card.Text className="details">United Kingdom</Card.Text>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Col>
                        <Col lg={6} md={8}>
                            <Card.Body className='contact-form'>
                                <Form action="#" id="contact_page_form" className="contact-page-form style-01" method="post">
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className='form-group'>
                                                <Form.Control type="text" name="name" placeholder="Your Name *" aria-required="true" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className='form-group'>
                                                <Form.Control type="text" name="email" placeholder="Your E-Mail *" aria-required="true" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className='form-group'>
                                                <Form.Control type="text" name="phone" placeholder="Telephone *" aria-required="true" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className='form-group'>
                                                <Form.Control as="textarea" cols={1} rows={3} name="message" id="msg" placeholder="Send Message" aria-required="true" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className='btn-wrapper'>
                                                <Form.Control as="input" type="submit" value="Send Message" className="boxed-btn" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Contact