import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { howitworkBannerDetails } from '../../../../data/pages/howitwork';

const HowITworkDetail = (props) => {
    const { classname, id, title, description } = props;
    return (
        <Col lg={4} md={6} sm={6}>
            <Card.Body className="how-it-single-item">
                <div className={'icon ' + classname}>
                    <span className="day">{id}</span>
                </div>
                <Card.Footer className="content">
                    <h3 className="title">{title}</h3>
                    <Card.Text>{description}</Card.Text>
                </Card.Footer>
            </Card.Body>
        </Col>
    );
};

function HowITwork() {
    return (
        <>
            <Card className='how-it-work-area padding-bottom-90 rounded-0'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={10}>
                            <Card.Body className='section-title startup desktop-center margin-bottom-55'>
                                <h3 className="title">How it work</h3>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        {howitworkBannerDetails.map(data => <HowITworkDetail key={`howitworkBanner-${data.id}`} {...data} />)}
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default HowITwork