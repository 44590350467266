import React from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';

const media = [
  {
    type: "yt",
    videoID: "IWVJq-4zW24",
    thumbnail: "https://img.youtube.com/vi/IWVJq-4zW24/hqdefault.jpg",
    alt: "Brand new app to blow your mind"
  }
]
function HeaderSlider() {
  return (
    <>
      <Card className="header-area header-startup header-bg" style={{ backgroundImage: 'url(assets/img/header-slider/startup/bg.png)' }}>
        <div className="startup-bg-img wow animate__animated animate__zoomIn" data-parallax="{&quot;x&quot;: 220, &quot;y&quot;: 150}" style={{ backgroundImage: 'url(assets/img/header-slider/startup/01.png)' }} />
        <div className="shape" />
        <div className="shape-02" />
        <Container>
          <Row>
            <Col lg={6}>
              <Card.Body className="header-inner">
                <span> Spending too much time on grow business? </span>
                <h1 className="title">We can fix that</h1>
                <Card.Text>Inspiration comes in many ways and you like to save .</Card.Text>
                <Card.Text>
                  Inspiration comes in many ways and you like to save everything from. sed do eiusmod tempor incididunt.
                </Card.Text>
                <Form className="header-form-area">
                  <Form.Group className="form-group">
                    <Form.Control type="text" placeholder="Enter your email" />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Button type="submit" className="submit-btn">Try 7 Days Free</Button>
                  </Form.Group>
                </Form>
                <Card.Footer className="vdo-btn">
                  <SlideshowLightbox images={media} lightboxIdentifier="lbox1" theme="lightbox">
                    <div className="play-icon video-play mfp-iframe" data-lightboxjs="lbox1" ><i className="fas fa-play" />Watch Tutorial</div>
                  </SlideshowLightbox>
                </Card.Footer>
              </Card.Body>
            </Col>
          </Row>
        </Container>
      </Card>

    </>

  )
}

export default HeaderSlider