import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { offerDetails } from '../../../data/home03/offer';

const OfferDetail = (props) => {
    const { icon, description, title, classname } = props;
    return (
        <Col lg={6}>
            <Card.Body className="offer-single-item">
                <Card.Header className={"icon p-0 " + classname}>
                    <i className={icon}></i>
                </Card.Header>
                <Card.Footer className="content border-0">
                    <h4 className="title">
                        <Card.Link href='#'>{title}</Card.Link>
                    </h4>
                    <Card.Text>{description}</Card.Text>
                </Card.Footer>
            </Card.Body>
        </Col>
    );
};


function OfferItem() {
    return (
        <>
            <Card className="offer-item-area">
                <Container>
                    <Card.Body className='offer-item-wrap m-bottom'>
                        <Row>
                            {offerDetails.map(offer => <OfferDetail key={`offer-${offer.id}`} {...offer} />)}
                        </Row>
                    </Card.Body>
                </Container>
            </Card>
        </>
    )
}

export default OfferItem