import React, { useEffect, useState } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import MapContainer from './MapContainer';
import ContactForm from './ContactForm';
import Breadcrumb from './Breadcrumb';
import LoadingIndicator from '../../../LoadingIndicator';


function Contact() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate some asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Header />
          <>
            <Breadcrumb />
            <ContactForm />
            <MapContainer />
          </>
          <Footer />
        </>
      )}
    </>
  )
}

export default Contact