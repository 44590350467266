import React from 'react';
import { Card, Col, Container, Image, ListGroup, Row } from 'react-bootstrap';

function Footer() {

    return (
        <>
            <footer id="home02Footer" className="footer-area">
                <Card className="footer-top style-01 padding-top-85 padding-bottom-50 rounded-0">
                    <Container>
                        <Row className="footer-top-border padding-bottom-30">
                            <Col lg={3} md={6} sm={6}>
                                <Card.Body className="footer-widget widget">
                                    <div className="about_us_widget">
                                        <Card.Link href="/index-2#" className="footer-logo" style={{width:'200px'}}> <Image src="assets/img/logo.png" alt="footer logo" /></Card.Link>
                                    </div>
                                </Card.Body>
                            </Col>
                            <Col lg={2} md={6} sm={6}>
                                <Card.Body className='footer-widget style-01 widget widget_nav_menu bginherit'>
                                    <ListGroup as="ul" id="listGroupUl">
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>About Us</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Features</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Pricing</Card.Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Col>
                            <Col lg={2} md={6} sm={6}>
                                <Card.Body className='footer-widget style-01 widget widget_nav_menu bginherit'>
                                    <ListGroup as="ul" id="listGroupUl">
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Blog</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Help Center</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Contact</Card.Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Col>
                            <Col lg={3} md={6} sm={6}>
                                <Card.Body className='footer-widget style-01 widget widget_nav_menu bginherit'>
                                    <ListGroup as="ul" id="listGroupUl">
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>FAQ’S</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Testimonial</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Terms &amp; Conditions</Card.Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Col>
                            <Col lg={2} md={6} sm={6}>
                                <Card.Body className='footer-widget widget widget_nav_menu bginherit'>
                                    <Card className='download-link margin-bottom-20 bginherit'>
                                        <Card.Link href="#">
                                            <Image src="assets/img/download/01.png" />
                                        </Card.Link>
                                    </Card>
                                    <Card className='download-link bginherit'>
                                        <Card.Link href="#">
                                            <Image src="assets/img/download/02.png" />
                                        </Card.Link>
                                    </Card>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Container>
                </Card>
                <Card className="copyright-area">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card.Body className='copyright-item'>
                                    <Card className="copyright-area-inner bginherit">
                                        © Copyrights 2023 CodeyesAll rights reserved.
                                    </Card>
                                    <Card className='widget widget_nav_menu bginherit'>
                                        <ListGroup as="ul" id="listGroupUlfollowHome2" className='social_share style-01'>
                                            <ListGroup.Item as="li">
                                                <Card.Link to='#'><i className="fab fa-linkedin-in"></i></Card.Link>
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li">
                                                <Card.Link to='#'><i className="fab fa-facebook-f"></i></Card.Link>
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li">
                                                <Card.Link to='#'><i className="fab fa-twitter"></i></Card.Link>
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li">
                                                <Card.Link to='#'><i className="fab fa-twitter"></i></Card.Link>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            </footer>
        </>
    )
}

export default Footer