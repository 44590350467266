import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { ideaDetails } from '../../../data/home03/idea';


const IdeaDetail = (props) => {
    const { icon, description, title, classname } = props;
    return (
        <Col lg={4} md={6}>
            <Card.Body className="single-everything-item">
                <Card.Header className={"icon p-0 " + classname}>
                    <i className={icon}></i>
                </Card.Header>
                <Card.Footer className="content border-0">
                    <h4 className="title">
                        <Card.Link href='#'>{title}</Card.Link>
                    </h4>
                    <Card.Text>{description}</Card.Text>
                </Card.Footer>
            </Card.Body>
        </Col>
    );
};

function Idea() {
    return (
        <>
            <Card id="idea" className="build-area">
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={8}>
                            <Card.Body className="section-title desktop-center margin-bottom-55">
                                <h4 className="title social-title">Everything you’ll need. Nothing you wont </h4>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        {ideaDetails.map(data => <IdeaDetail key={`Idea-${data.id}`} {...data} />)}
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Idea