import React, { useState } from "react";
import { Card, Col, Container, Row } from 'react-bootstrap';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";

function MapContainer(props) {
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);

  const onMarkerClick = (props, marker) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const onInfoWindowClose = () => {
    setActiveMarker(null);
    setShowingInfoWindow(false);
  };

  const onMapClicked = () => {
    if (showingInfoWindow) {
      setActiveMarker(null);
      setShowingInfoWindow(false);
    }
  };

  if (!props.loaded) return <div>Loading...</div>;

  return (
    <Card className="map-area padding-bottom-120 rounded-0">
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <Card.Body className="contact_map">
              <Map
                google={props.google}
                onClick={onMapClicked}
                initialCenter={{ lat: 51.5072, lng: 0.1276 }}
                zoom={4}
              >
                <Marker
                  name="Welcome To digiTechno"
                  onClick={onMarkerClick}
                  position={{ lat: 51.5072, lng: 0.1276 }}
                />

                <InfoWindow
                  marker={activeMarker}
                  onClose={onInfoWindowClose}
                  visible={showingInfoWindow}
                >
                  <div>
                    <h4>{selectedPlace.name}</h4>
                  </div>
                </InfoWindow>
              </Map>
            </Card.Body>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

export default GoogleApiWrapper({
  apiKey: ""
})(MapContainer);
