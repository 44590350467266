
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import { testimonialDetails } from '../../../data/home03/testimonials';

const TestimonialDetail = (props) => {
    const { image, description, title } = props;
    return (
        <Card className="single-testimonial-item-03">
            <Card.Body className="content">
                <Card className="content-wrap">
                    <Card.Header className="icon">
                        <i className="flaticon-quote-left"></i>
                    </Card.Header>
                    <Card.Text className="description">{description}</Card.Text>
                    <Card.Footer className="author-details">
                        <div className="author-meta">
                            <h4 className="title">{title}</h4>
                        </div>
                    </Card.Footer>
                </Card>
            </Card.Body>
            <Card.Footer className="thumb">
                <Card.Img src={image} />
            </Card.Footer>
        </Card>
    );
};

const options = {
    loop: true,
    autoplay: true, //true if you want enable autoplay
    autoplaytimeout: 1000,
    margin: 30,
    dots: false,
    nav: false,
    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    responsive: {
        0: {
            items: 1
        },
        460: {
            items: 1
        },
        599: {
            items: 1
        },
        768: {
            items: 1
        },
        960: {
            items: 1
        },
        1200: {
            items: 1
        },
        1920: {
            items: 1
        }
    }
};

function Testimonial() {
    return (
        <>
            <Card className="testimonial-area padding-top-110">
                <Container>
                    <Row className='justify-content-center'>
                        <Col xl={6} lg={8}>
                            <Card.Body className='section-title startup desktop-center margin-bottom-55'>
                                <h3 className="title">What our customer’s say</h3>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col lg={10}>
                            <Card.Body className='testimonial-carousel-area'>
                                <OwlCarousel {...options}>
                                    {testimonialDetails.map(testimonial => <TestimonialDetail key={`testimonial03-${testimonial.id}`} {...testimonial} />)}
                                </OwlCarousel>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Testimonial