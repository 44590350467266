import React from "react";
import { Outlet } from "react-router-dom";

const Layout = () => {

    return (
        <>
            <>
                {/* <div className="preloader" id="preloader">
                    <div className="preloader-inner">
                        <div></div>
                        <hr />
                    </div>
                </div> */}
                <Outlet />
                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up" /></span>
                </div>
            </>
        </>
    );
};

export default Layout;