import React, { useEffect, useState } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Breadcrumb from './Breadcrumb';
import Contactarea from './Contactarea';
import LoadingIndicator from '../../../LoadingIndicator';


function ComingSoon() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate some asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Header />
          <>
            <Breadcrumb />
            <Contactarea />
          </>
          <Footer />
        </>
      )}
    </>
  )
}

export default ComingSoon