
const packageDetails = [
    { id: 1, image: "url(assets/img/package/01.jpg)", title: "Thailand", price: "$250" },
    { id: 2, image: "url(assets/img/package/02.jpg)", title: "Thailand", price: "$100" },
    { id: 3, image: "url(assets/img/package/03.jpg)", title: "Thailand", price: "$200" },
    { id: 4, image: "url(assets/img/package/04.jpg)", title: "Thailand", price: "$450" },
];

export {
    packageDetails
};