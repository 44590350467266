import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function HeaderSlider() {
  return (
    <>
      <Card className='header-area header-bg' style={{ backgroundImage: 'url(assets/img/header-slider/01.png)' }}>
        <div className="bg-img" style={{ backgroundImage: 'url(assets/img/balloon/bike.png)' }} />
        <div className="bg-img-02" style={{ backgroundImage: 'url(assets/img/balloon/balloon.png)' }} />
        <div className="bg-img-03" style={{ backgroundImage: 'url(assets/img/balloon/balloon-03.png)' }} />
        <div className="bg-img-04" style={{ backgroundImage: 'url(assets/img/balloon/balloon-02.png)' }} />
        <div className="bg-img-05" style={{ backgroundImage: 'url(assets/img/leaf/leaf-01.png)' }} />
        <div className="bg-img-06" style={{ backgroundImage: 'url(assets/img/leaf/leaf-02.png)' }} />
        <div className="bg-img-07" style={{ backgroundImage: 'url(assets/img/leaf/leaf-04.png)' }} />
        <div className="bg-img-08" style={{ backgroundImage: 'url(assets/img/leaf/leaf-03.png)' }} />
        <div className="bg-img-09" style={{ backgroundImage: 'url(assets/img/leaf/leaf-05.png)' }} />
        <div className="bg-img-10" style={{ backgroundImage: 'url(assets/img/leaf/leaf-06.png)' }} />
        <div className="bg-img-11" style={{ backgroundImage: 'url(assets/img/balloon/cercle.png)' }} />
        <Container>
          <Row>
            <Col lg={5}>
              <Card.Body className="header-inner">
                <h1 className="title">Lets pack for your next adventure</h1>
                <Card.Header className="btn-wrapper desktop-left padding-top-30">
                  <Card.Link href="#" className="boxed-btn">Send App to Your Phone</Card.Link>
                </Card.Header>
              </Card.Body>
            </Col>
          </Row>
        </Container>
      </Card>
    </>

  )
}

export default HeaderSlider