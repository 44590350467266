import React, { useEffect, useState } from "react";

const Clock = ({ deadline }) => {
    const [month, setMonth] = useState(0);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const leading0 = (num) => {
        return num < 10 ? "0" + num : num;
    };

    const getTimeUntil = (deadline) => {
        const time = Date.parse(deadline) - Date.parse(new Date());
        if (time < 0) {
            setMonth(0);
            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);
        } else {
            setMonth(Math.floor(time / (1000 * 60 * 60 * 24 * 30.4368)));
            setDays(Math.floor(time / (1000 * 60 * 60 * 24) % 30.4368));
            setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
            setMinutes(Math.floor((time / 1000 / 60) % 60));
            setSeconds(Math.floor((time / 1000) % 60));
        }
    };

    useEffect(() => {
        setInterval(() => getTimeUntil(deadline), 1000);

        return () => getTimeUntil(deadline);
    }, [deadline]);

    return (
        <div id="mycountdown" className="counter-single-item">
            <ul>
                <li className="counter-item Clock-month" >
                    <span className="month">{leading0(month)}</span>
                    <h6>Month</h6>
                </li>
                <li className="counter-item Clock-days">
                    <span className="days">{leading0(days)}</span>
                    <h6>Days</h6>
                </li>
                <li className="counter-item Clock-hours">
                    <span className="hours">{leading0(hours)}</span>
                    <h6>Hours</h6>
                </li>
                <li className="counter-item Clock-minutes">
                    <span className="mins">{leading0(minutes)}</span>
                    <h6>Minuts</h6>
                </li>
                <li className="counter-item Clock-seconds">
                    <span className="secs">{leading0(seconds)}</span>
                    <h6>Seconds</h6>
                </li>
            </ul>
        </div>
    );
};

export default Clock;
