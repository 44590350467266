import React, { useEffect, useState } from 'react';
import ChooseUs from './ChooseUs';
import Footer from './Common/Footer';
import Header from './Common/Header';
import Contact from './Contact';
import Destination from './Destination';
import HeaderSlider from './HeaderSlider';
import Packages from './Packages';
import Testimonial from './Testimonial';
import Trip from './Trip';
import LoadingIndicator from '../../LoadingIndicator';

const Home = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate some asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 1600);
    }, []);

    return (
        <>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
            <>
                <Header />
                <>
                    <HeaderSlider />
                    <Packages />
                    <ChooseUs />
                    <Destination />
                    <Trip />
                    <Testimonial />
                    <Contact />
                </>
                <Footer />
            </>
            )}
        </>
    )
}

export default Home

