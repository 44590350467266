import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import { testimonialDetails } from "../../../data/home01/testimonials";

const TestimonialDetail = (props) => {
    const { image, description, title } = props;
    return (
        <Card className="single-testimonial-item">
            <Card.Header className="thumb bg-image">
                <Card.Img src={image} />
            </Card.Header>
            <Card.Body className="content">
                <Card.Text className="description">{description}</Card.Text>
                <Card.Footer className="author-details">
                    <div className="author-meta">
                        <h4 className="title">{title}</h4>
                    </div>
                </Card.Footer>
            </Card.Body>
        </Card>
    );
};

const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    margin: 30,
    dots: false,
    nav: false,
    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    responsive: {
        0: {
            items: 1
        },
        460: {
            items: 1
        },
        599: {
            items: 1
        },
        768: {
            items: 2
        },
        960: {
            items: 2
        },
        1200: {
            items: 3
        },
        1920: {
            items: 3
        }
    }
};

function Testimonial() {
    return (
        <>
            <Card id='testimonial' className="testimonial-area bg-image padding-top-110 padding-bottom-120" style={{ backgroundImage: 'url(assets/img/testimonial/01.png)' }}>
                <Container>
                    <Row className='justify-content-center'>
                        <Col xl={6} lg={8}>
                            <Card.Body className='section-title desktop-center white margin-bottom-55'>
                                <h3 className="title">More then 30k+ tarvellers satisfied with our journey</h3>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card.Body className='testimonial-carousel-area'>
                                <OwlCarousel {...options}>
                                    {testimonialDetails.map(testimonial => <TestimonialDetail key={`testimonial01-${testimonial.id}`} {...testimonial} />)}
                                </OwlCarousel>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Testimonial