import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function Offer() {
    return (
        <>
            <Card className="offer-area padding-bottom-120 padding-top-400 bg-image rounded-0" style={{ backgroundImage: 'url(assets/img/bg/startup/bg.png)' }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <Card.Body className="section-title white startup  desktop-center">
                                <h3 className="title">We are offering 14 days free trial for early 1k users</h3>
                                <Card className="btn-wrapper margin-top-30 bginherit">
                                    <Card.Body className="boxed-btn btn-startup">Try 14 Days free trial</Card.Body>
                                </Card>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Offer