import React, { useContext } from 'react';
import { Accordion, AccordionContext, Card, Col, Container, Row, useAccordionButton } from 'react-bootstrap';
import { faqDetail } from '../../../../data/pages/faq';

function CustomToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <h5 className="mb-0">
            <Card.Link className="collapsed" onClick={decoratedOnClick} aria-expanded={isCurrentEventKey ? 'true' : 'false'}>
                {children}
            </Card.Link>
        </h5>
    );
}

const FaqDetail = (props) => {
    const { id, question, description, delay } = props;
    return (
        <Card className="wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay={delay}>
            <Card.Header id="headingOne">
                <CustomToggle eventKey={id}>{question}</CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={id}>
                <Card.Body>{description}</Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

function Accoridions() {
    return (
        <>
            <Card id="faq" className="accoridions padding-bottom-120 rounded-0">
                <Container>
                    <Row className='justify-content-center padding-bottom-50'>
                        <Col lg={8} md={12}>
                            <Card.Body className='section-title startup desktop-center margin-bottom-55'>
                                <h3 className="title">Frequently asked quetions</h3>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col lg={8}>
                            <Card.Body className="accordion-wrapper">
                                <Accordion id="accordion">
                                    {faqDetail.map(faq => <FaqDetail key={`faq-${faq.id}`} {...faq} />)}
                                </Accordion>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Accoridions