import React from 'react';
import { Badge, Card, Col, Container, Image, ListGroup, Row } from 'react-bootstrap';
import { priceDetails } from '../../../data/home03/price';

const PriceDetail = (props) => {
    const { title, image, price } = props;
    return (
        <Col lg={4} md={6}>
            <Card.Body className="single-price-plan-01">
                <Card className="price-header">
                    <h4 className="title">{title}</h4>
                    <Card.Header className="img-icon">
                        <Image src={image} />
                    </Card.Header>
                </Card>
                <Card className="price-wrap">
                    <Badge className="price" bg="inherit">${price}</Badge><Badge bg="inherit" className="month">/mo</Badge>
                </Card>
                <Card className="price-body">
                    <ListGroup as="ul">
                        <ListGroup.Item as="li">
                            <i className="fa fa-check success" /> 8 Social Accounts
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            <i className="fa fa-check success" /> 100 Schedule Posts
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            <i className="fa fa-check success" /> 1 User
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
                <Card className="price-footer">
                    <Card.Footer className="btn-wrapper">
                        <Card.Link href="#" className="boxed-btn">get started</Card.Link>
                    </Card.Footer>
                </Card>
            </Card.Body>
        </Col>
    );
};

function Priceplan() {
    return (
        <>
            <Card id="pricing" className="price-plan-area padding-top-110 padding-bottom-90">
                <div className="bg-img" style={{ backgroundImage: 'url(assets/img/bg/social/01.png)' }} />
                <div className="bg-img-02" style={{ backgroundImage: 'url(assets/img/bg/social/02.png)' }} />
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={8}>
                            <Card.Body className='section-title desktop-center margin-bottom-55'>
                                <h3 className="title social-title">Choose the best pricing</h3>
                                <Card.Text>Inspiration comes in many ways and you like to save everything from.</Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        {priceDetails.map(data => <PriceDetail key={`price-${data.id}`} {...data} />)}
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Priceplan