import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function UniqueProject() {
    return (
        <>
            <Card id="why" className="unique-project-area rounded-0">
                <Container>
                    <Row>
                        <Col lg={6}>
                            <Card.Body className='unique-content-area'>
                                <Card.Title className='section-title brand'>
                                    <h4 className="title">
                                        An award-winning design agency delivering outstanding solutions
                                    </h4>
                                    <Card.Text>Each time a digital asset is purchased or sold, Sequoir donates a percentage of the fees back into the development of the asset through its charitable foundation.</Card.Text>
                                    <Card.Text>Digital asset is purchased or sold, Sequoir donates a percentage of the fees back into the development of the asset through its charitable foundation.</Card.Text>
                                </Card.Title>
                            </Card.Body>
                        </Col>
                        <Col lg={6}>
                            <Card.Body className='shape'>
                                <div className="build-img bg-image-02 wow animate__ animate__backInUp animated" style={{ backgroundImage: 'url(../assets/img/unique-project/01.png)', visibility: 'visible', animationName: 'backInUp' }} />
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default UniqueProject