import React from 'react';
import { Badge, Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { contentDetails } from '../../../data/home02/content';

const ContentDetail = (props) => {
    const { strongcontent, content } = props;
    return (
        <ListGroup.Item as="li">
            <i aria-hidden="true" className="fas fa-check"></i><strong>{strongcontent}</strong>{content}
        </ListGroup.Item>
    );
};

function CreateContent() {
    return (
        <>
            <Card className="create-content-area">
                <Container>
                    <Row>
                        <Col lg={6}>
                            <Card.Title className="section-title padding-bottom-25">
                                <Badge className="social-span" bg="inherit">Analyze Post</Badge>
                                <h4 className="title social-title">Measure and report on the performance of your recent content</h4>
                            </Card.Title>
                            <ListGroup as="ul" className='content'>
                                {contentDetails.map(data => <ContentDetail key={`content-${data.id}`} {...data} />)}
                            </ListGroup>
                        </Col>
                        <Col lg={6}>
                            <div className="trip-img wow animate__animated animate__backInDown bg-image" data-wow-duration="2s" data-wow-delay="0.2s" data-wow-offset="60" data-parallax="{&quot;x&quot;: -100, &quot;y&quot;: 0}" style={{ backgroundImage: 'url(assets/img/anayzlest/01.png)' }} />
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default CreateContent