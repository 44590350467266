import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';


function Breadcrumb() {
    return (
        <>
            <Card className='breadcrumb-area rounded-0 margin-bottom-120'>
                <div className="shape"></div>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <Card.Body className='breadcrumb-inner'>
                                <div className="icon">
                                    <i className="far fa-lightbulb" />
                                    <Card.Text>FAQs</Card.Text>
                                </div>
                            </Card.Body>
                        </Col>
                        <Col lg={6}>
                            <Card.Body className='breadcrumb-inner'>
                                <h2 className="page-title">
                                    We’ll Be Glad To Assist You!
                                </h2>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Breadcrumb