import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { buildareaDetails } from '../../../../data/pages/buildarea';

const BuildareaDetail = (props) => {
    const { classname, icon, id, description } = props;
    return (
        <Col lg={4} md={6}>
            <Card.Body className="single-icon-box-04">
                <div className={'icon ' + classname}>
                    <i className={icon} />
                </div>
                <Card.Footer className="content">
                    <h3 className="title">{id === 1 ? 'Strategy & Design' : id === 2 ? 'Development' : 'Analysis & Reporting'}</h3>
                    <Card.Text>{description}</Card.Text>
                </Card.Footer>
            </Card.Body>
        </Col>
    );
};

function OrganizationsHeader() {
    return (
        <>
            <Card className='organizations-header-section rounded-0'>
                <Container>
                    <Card.Body className='organizations-header-wrap bg-image' style={{ backgroundImage: 'url(../assets/img/map/map.png)' }}>
                        <Row className='justify-content-center'>
                            <Col lg={8}>
                                <Card.Title className='section-title brand white desktop-center padding-bottom-45'>
                                    <Card.Text>Why choose us</Card.Text>
                                    <h3 className="title">We are awesome inventor</h3>
                                </Card.Title>
                            </Col>
                        </Row>
                        <Row>
                            {buildareaDetails.map(data => <BuildareaDetail key={`buildarea-${data.id}`} {...data} />)}
                        </Row>
                    </Card.Body>
                </Container>
            </Card>
        </>
    )
}

export default OrganizationsHeader