import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { organizationsDetails } from '../../../../data/pages/organizations';

const OrganizationsDetail = (props) => {
    const { image, title } = props;
    return (
        <Col lg={4} md={6}>
            <Card.Body className="hard-single-item-02 margin-bottom-30">
                <Card.Header className="thumb water-effect" style={{ backgroundImage: image }}>
                    <div className="content">
                        <h4 className="title">{title}</h4>
                        <Card.Text className="catagory">Mobile App</Card.Text>
                    </div>
                </Card.Header>
            </Card.Body>
        </Col>
    );
};

function Organizations() {
    return (
        <>
            <Card className='our-organizations-area rounded-0'>
                <Container fluid>
                    <Row>
                        {organizationsDetails.map(data => <OrganizationsDetail key={`organization-${data.id}`} {...data} />)}
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Organizations