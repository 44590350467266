import { packageDetails } from '../../../data/home01/package';
import React from 'react';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';

const PackageDetail = (props) => {
  const { image, title, price } = props;
  return (
    <Col lg={3} md={6} sm={6}>
      <Card className="single-package-item water-effect bg-image" style={{ backgroundImage: image }}>
        <Card.Body className="content">
          <Card.Footer className="btn-wrapper">
            <Card.Link href="#" className="boxed-btn">{price}</Card.Link>
          </Card.Footer>
          <h4 className="title">
            <Card.Link href='#'>{title}</Card.Link>
          </h4>
        </Card.Body>
      </Card>
    </Col>
  );
};

function Packages() {
  return (
    <>
      <Card id="packages" className="header-bottom-area padding-top-110">
        <Container>
          <Row className='justify-content-center'>
            <Col lg={4}>
              <Card.Title className="section-title desktop-center margin-bottom-60">
                <Badge className="sub-title" bg="inherit">Top Packages</Badge>
                <h4 className="title">Explore <Badge bg="inherit">unique places</Badge> & get <Badge bg="inherit">amazing</Badge> experience </h4>
              </Card.Title>
            </Col>
          </Row>
          <Row>
            {packageDetails.map(data => <PackageDetail key={`package-${data.id}`} {...data} />)}
          </Row>
        </Container>
      </Card>
    </>
  )
}

export default Packages