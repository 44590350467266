import 'animate.css/animate.min.css';
import React, { useEffect } from 'react';
import { Card, Container, Image, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import WOW from 'wowjs';

const smoothScrollToTop = () => {
    const scrollStep = -window.scrollY / (500 / 15); // Adjust the duration and step as needed
    const scrollInterval = setInterval(() => {
        if (window.scrollY !== 0) {
            window.scrollBy(0, scrollStep);
        } else {
            clearInterval(scrollInterval);
        }
    }, 15); // Adjust the interval as needed
};

function Header() {

    const handleLinkClick = () => {
        smoothScrollToTop();
    };

    useEffect(() => {
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);

    return (
        <>
            <Card className='header-style-01 rounded-0'>
                <Navbar id="header03" expand="lg" className="navbar-area nav-style-02">
                    <Container className="nav-container  startup-nav">
                        <Navbar.Brand className="responsive-mobile-menu" style={{ marginRight: '0rem' }}>
                            <Card.Body className="logo-wrapper">
                                <Link to="/index-3" className="logo">
                                    <Image src="assets/img/logo-02.png" />
                                </Link>
                            </Card.Body>
                            <Navbar.Toggle aria-controls="bizcoxx_main_menu" />
                        </Navbar.Brand>
                        <Navbar.Collapse id="bizcoxx_main_menu">
                            <Nav as="ul">
                                <Nav.Item as="li" className="menu-item-has-children current-menu-item">
                                    <Link to="#">Home</Link>
                                    <Nav as="ul" className="sub-menu">
                                        <Nav.Item as="li">
                                            <Link to="/" onClick={handleLinkClick}>Home 01</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link to="/index-2" onClick={handleLinkClick}>Home 02</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link to="/index-3" onClick={handleLinkClick}>Home 03</Link>
                                        </Nav.Item>
                                    </Nav>
                                </Nav.Item>
                                <Nav.Item as="li" className="menu-item-has-children">
                                    <Link to="#">Pages</Link>
                                    <Nav as="ul" className="sub-menu">
                                        <Nav.Item as="li">
                                            <Link to="/pages/about">About Us</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link to="/pages/contact">Contact Us</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link to="/pages/service">Services</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link to="/pages/how-it-work">How it work</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link to="/pages/faq">FAQs</Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link to="/pages/coming-soon">Coming Soon</Link>
                                        </Nav.Item>
                                    </Nav>
                                </Nav.Item>
                                <Nav.Item as="li" >
                                    <HashLink smooth to="/index-3#idea">What idea does?</HashLink>
                                </Nav.Item>
                                <Nav.Item as="li" >
                                    <HashLink smooth to="/index-3#overview">Overview</HashLink>
                                </Nav.Item>
                                <Nav.Item as="li" >
                                    <HashLink smooth to="/index-3#pricing">Pricing</HashLink>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                        <Card.Body className="nav-right-content flexnonecbody">
                            <Card.Header className="btn-wrapper">
                                <Card.Link href="#" className="boxed-btn btn-startup blank">Login</Card.Link>
                                <Card.Link href="#" className="boxed-btn btn-startup reverse-color">Sign Up</Card.Link>
                            </Card.Header>
                        </Card.Body>
                    </Container>
                </Navbar>
            </Card>
        </>
    )
}

export default Header