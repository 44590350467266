import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { buildareaDetails } from '../../../../data/pages/buildarea';

const BuildareaDetail = (props) => {
    const { classname, icon, id, description } = props;
    return (
        <Col lg={4} md={6}>
            <Card.Body className="single-icon-box-04">
                <div className={'icon ' + classname}>
                    <i className={icon} />
                </div>
                <Card.Footer className="content">
                    <h3 className="title">{id === 1 ? 'Strategy & Design' : id === 2 ? 'Development' : 'Analysis & Reporting'}</h3>
                    <Card.Text>{description}</Card.Text>
                </Card.Footer>
            </Card.Body>
        </Col>
    );
};

function BuildArea() {
    return (
        <Card id="what" className="build-area padding-top-120 padding-bottom-90 rounded-0">
            <Container>
                <Row>
                    {buildareaDetails.map(data => <BuildareaDetail key={`buildarea-${data.id}`} {...data} />)}
                </Row>
            </Container>
        </Card>
    )
}

export default BuildArea