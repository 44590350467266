
const offerDetails = [
    { id: 1, classname: '', icon: "flaticon-padlock-2", title: "Secure authentication", description: "Inspiration comes in many ways and you like to save everything from. sed do eiusmod tempor incididunt ut labore et dolor." },
    { id: 2, classname: 'style-01', icon: "flaticon-send", title: "Paid Subscription", description: "Inspiration comes in many ways and you like to save everything from. sed do eiusmod tempor incididunt ut labore et dolor." },
    { id: 3, classname: 'style-02', icon: "flaticon-fast-delivery", title: "Fast, Global Manager", description: "Inspiration comes in many ways and you like to save everything from. sed do eiusmod tempor incididunt ut labore et dolor." },
    { id: 4, classname: 'style-03', icon: "flaticon-privacy", title: "Personalised experiences", description: "Inspiration comes in many ways and you like to save everything from. sed do eiusmod tempor incididunt ut labore et dolor." },
];

export {
    offerDetails
};