
const testimonialDetails = [
    { id: 1, image: "assets/img/testimonial/03.png", title: "Jonathon Doe", description: "Inspiration comes in many ways and you like to save everything from. sed do exercitation." },
    { id: 2, image: "assets/img/testimonial/04.png", title: "Jonathon Doe", description: "Inspiration comes in many ways and you like to save everything from. sed do exercitation." },
    { id: 3, image: "assets/img/testimonial/05.png", title: "Jonathon Doe", description: "Inspiration comes in many ways and you like to save everything from. sed do exercitation." },
    { id: 4, image: "assets/img/testimonial/04.png", title: "Jonathon Doe", description: "Inspiration comes in many ways and you like to save everything from. sed do exercitation." },
];

export {
    testimonialDetails
};