import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { howitworkDetails } from '../../../../data/pages/howitwork';

const QualityWorksDetail = (props) => {
    const { classname, icon, title, description } = props;
    return (
        <Col lg={6} md={6}>
            <Card.Body className="work-single-item margin-bottom-30">
                <Card.Footer className="content">
                    <div className={'icon ' + classname}>
                        <i className={icon} />
                    </div>
                    <h3 className="title">{title}</h3>
                    <Card.Text>{description}</Card.Text>
                </Card.Footer>
            </Card.Body>
        </Col>
    );
};

function QualityWorks() {
    return (
        <>
            <Card className='quality-works-area padding-bottom-85 padding-top-115 rounded-0'>
                <Container>
                    <Row>
                        <Col lg={7}>
                            <Row>
                                <Col lg={10}>
                                    <Card.Body className='section-title startup margin-bottom-55'>
                                        <Card.Text>Quality Works</Card.Text>
                                        <h3 className="title">We put our best effort into each unique project</h3>
                                    </Card.Body>
                                </Col>
                            </Row>
                            <Row>
                                {howitworkDetails.map(data => <QualityWorksDetail key={`howitwork-${data.id}`} {...data} />)}
                            </Row>
                        </Col>
                        <Col lg={5}>
                            <Card.Body className='work-single-img'>
                                <Image src="../assets/img/work/01.png" />
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default QualityWorks