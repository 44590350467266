import React, { useEffect, useState } from 'react';
import Footer from './Common/Footer';
import Header from './Common/Header';
import CreateContent from './CreateContent';
import Faq from './Faq';
import Feature from './Feature';
import HeaderBottom from './HeaderBottom';
import HeaderSlider from './HeaderSlider';
import Joinapps from './Joinapps';
import Overview from './Overview';
import Priceplan from './Priceplan';
import Testimonial from './Testimonial';
import LoadingIndicator from '../../LoadingIndicator';


const Home = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate some asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);

    return (
        <>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <Header />
                    <>
                        <HeaderSlider />
                        <HeaderBottom />
                        <Feature />
                        <Joinapps />
                        <Overview />
                        <CreateContent />
                        <Priceplan />
                        <Testimonial />
                        <Faq />
                    </>
                    <Footer />
                </>
            )}
        </>
    )
}

export default Home

