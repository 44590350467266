import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';

function Joinapps() {
    return (
        <>
            <Card className='join-apps-area'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={12}>
                            <Card.Body className="bg-image wow animate__animated animate__fadeInUp">
                                <Image src="assets/img/bg/social/bg.png" />
                            </Card.Body>
                        </Col>
                        <Col lg={10}>
                            <Card.Body className='section-title desktop-center padding-top-110'>
                                <h3 className="title social-title">Join the 2+ million already using this app</h3>
                                <Card.Text>Inspiration comes in many ways and you like to save everything from.</Card.Text>
                                <Card className='apps-download'>
                                    <Card.Header className='download-link style-01'>
                                        <Card.Link href="#"><i className="flaticon-apple"></i>Get For IOS</Card.Link>
                                    </Card.Header>
                                    <Card.Footer className='download-link'>
                                        <Card.Link href="#"><i className="flaticon-android"></i>Get For Android</Card.Link>
                                    </Card.Footer>
                                </Card>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Joinapps