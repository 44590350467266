import React from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import Clock from './Clock';

function Breadcrumb() {
    let deadline = "January, 01, 2025";
    return (
        <>
            <Card className='breadcrumb-area margin-bottom-120 rounded-0'>
                <div className="shape"></div>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={6}>
                            <Card.Body className='breadcrumb-inner desktop-center'>
                                <h2 className="page-title style-01">
                                    Coming Soon
                                </h2>
                                <Clock deadline={deadline} />
                                <Form action="#" className="notify-form" method="post">
                                    <Form.Group className='form-group'>
                                        <Form.Control type="text" name="email" placeholder="Your email address" aria-required="true" />
                                    </Form.Group>
                                    <Button type="submit" className="submit-btn rounded-0">Notify Me</Button>
                                </Form>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Breadcrumb