import React from 'react';
import { Card, Col, Container, Image, ListGroup, Row } from 'react-bootstrap';

function Footer() {

    return (
        <>
            <footer className="footer-area">
                <Card className="footer-top style-04 padding-top-85 padding-bottom-50 rounded-0">
                    <Container>
                        <Row>
                            <Col lg={4} md={6} sm={6}>
                                <Card.Body className="footer-widget widget">
                                    <div className="about_us_widget">
                                        <Card.Link href="#" className="footer-logo" style={{width:'200px'}}> <Image src="../assets/img/logo.png" alt="footer logo" /></Card.Link>
                                    </div>
                                </Card.Body>
                                <Card.Body className='widget widget_nav_menu'>
                                    <Card.Text>Follow us on</Card.Text>
                                    <ListGroup as="ul" id="listGroupUlfollow" className='social_share style-01'>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'><i className="fab fa-linkedin-in"></i></Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'><i className="fab fa-facebook-f"></i></Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'><i className="fab fa-twitter"></i></Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'><i className="fab fa-twitter"></i></Card.Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Col>
                            <Col lg={2} md={6} sm={6}>
                                <Card.Body className='footer-widget style-01 widget widget_nav_menu'>
                                    <h4 className="widget-title">Company</h4>
                                    <ListGroup as="ul" id="listGroupUl">
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>About Us</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Features</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Pricing</Card.Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Col>
                            <Col lg={2} md={6} sm={6}>
                                <Card.Body className='footer-widget style-01 widget widget_nav_menu'>
                                    <h4 className="widget-title">Products</h4>
                                    <ListGroup as="ul" id="listGroupUl">
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Blog</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Help Center</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Contact</Card.Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Col>
                            <Col lg={2} md={6} sm={6}>
                                <Card.Body className='footer-widget style-01 widget widget_nav_menu'>
                                    <h4 className="widget-title">Resources</h4>
                                    <ListGroup as="ul" id="listGroupUl">
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>FAQ’S</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Testimonial</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Terms &amp; Conditions</Card.Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Col>
                            <Col lg={2} md={6} sm={6}>
                                <Card.Body className='footer-widget style-01 widget widget_nav_menu'>
                                    <h4 className="widget-title">Relevent</h4>
                                    <ListGroup as="ul" id="listGroupUl">
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Why</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Products</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Customers</Card.Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Container>
                </Card>
                <Card className="copyright-area style-03">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card.Body className="copyright-area-inner">
                                    © Copyrights 2023 CodeyesAll rights reserved.
                                </Card.Body>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            </footer>
        </>
    )
}

export default Footer