import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { featureDetails } from '../../../data/home02/feature';

const FeatureDetail = (props) => {
  const { classname, classname2, icon, title, description } = props;
  return (
    <Col lg={4} md={6}>
      <Card.Body className={'single-icon-box-01' + classname}>
        <div className="msg-icon">
          <span />
          <span />
          <span />
        </div>
        <Card.Header className={"icon " + classname2}>
          <i className={icon} />
        </Card.Header>
        <Card.Footer className="content">
          <h3 className="title">{title}</h3>
          <Card.Text>{description}</Card.Text>
        </Card.Footer>
      </Card.Body>
    </Col>
  );
};

function Feature() {
  return (
    <>
      <Card id="feature" className="build-area padding-top-120 padding-bottom-75">
        <Container>
          <Row>
            {featureDetails.map(data => <FeatureDetail key={`feature-${data.id}`} {...data} />)}
          </Row>
        </Container>
      </Card>
    </>
  )
}

export default Feature