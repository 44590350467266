import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { workDetails } from '../../../../data/pages/work';

const WorkDetail = (props) => {
    const { image, title } = props;
    return (
        <Col lg={4} md={6}>
            <Card.Body className="hard-single-item margin-bottom-30">
                <Card.Header className="thumb water-effect" style={{ backgroundImage: image }}>
                </Card.Header>
                <div className="content">
                    <h4 className="title">{title}</h4>
                    <Card.Text className="catagory">Each time a digital asset is purchased or sold, Sequoir donates a percentage of the fees back</Card.Text>
                </div>
            </Card.Body>
        </Col>
    );
};

function Work() {

    return (
        <>
            <Card id="work" className="hard-work-area padding-bottom-90 rounded-0">
                <Container>
                    <Row>
                        {workDetails.map(data => <WorkDetail key={`workDetails-${data.id}`} {...data} />)}
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Work