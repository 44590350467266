import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function GetInTouch() {
    return (
        <>
            <Card id='get-in-touch-area' className='get-in-touch-area padding-bottom-100 padding-top-115 rounded-0'>
                <div className="bg-image" style={{ backgroundImage: 'url(../assets/img/get-in-touch/01.png)' }} />
                <div className="bg-image-02" style={{ backgroundImage: 'url(../assets/img/get-in-touch/02.png)' }} />
                <div className="bg-image-03" style={{ backgroundImage: 'url(../assets/img/get-in-touch/22.png)' }} />
                <Container>
                    <Row>
                        <Col lg={{ span: 4, offset: 3 }}>
                            <Card.Body className='section-title brand white'>
                                <Card.Text>Get In Touch</Card.Text>
                                <Card.Title className='title'>
                                    <Card.Link href="#">info@digi.com</Card.Link><br /> + (383) 409 5120
                                </Card.Title>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default GetInTouch