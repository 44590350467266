import React from 'react';
import { Badge, Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { contentDetails } from '../../../data/home02/content';

const ContentDetail = (props) => {
    const { strongcontent, content } = props;
    return (
        <ListGroup.Item as="li">
            <i aria-hidden="true" className="fas fa-check"></i><strong>{strongcontent}</strong>{content}
        </ListGroup.Item>
    );
};

function Overview() {

    return (
        <>
            <Card id="overview" className="create-content-area padding-bottom-120 padding-top-120">
                <Container>
                    <Row>
                        <Col lg={5}>
                            <div className="trip-img wow animate__animated animate__backInUp bg-image" data-wow-duration="2s" data-wow-delay="0.2s" data-wow-offset="60" data-parallax="{&quot;x&quot;: 100, &quot;y&quot;: 0}" style={{ backgroundImage: 'url(assets/img/contest/01.png)' }} />
                        </Col>
                        <Col lg={{ span: 6, offset: 1 }}>
                            <Card.Title className="section-title padding-bottom-25">
                                <Badge className="social-span" bg="inherit">Create Content</Badge>
                                <h4 className="title social-title">Whether you are browsing or the web you can easily post</h4>
                            </Card.Title>
                            <ListGroup as="ul" className='content'>
                                {contentDetails.map(data => <ContentDetail key={`content-${data.id}`} {...data} />)}
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Overview