
const whyDetails = [
    { id: 1, classname: '', icon: "flaticon-hotel", title: "Book Hotels", description: "Inspiration comes in many ways and you like to save everything from." },
    { id: 2, classname: 'style-01', icon: "flaticon-plane", title: "Book Flights", description: "Inspiration comes in many ways and you like to save everything from." },
    { id: 3, classname: 'style-02', icon: "flaticon-accounting", title: "Save Money", description: "Inspiration comes in many ways and you like to save everything from." },
    { id: 4, classname: 'style-03', icon: "flaticon-notification", title: "Get Notified", description: "Inspiration comes in many ways and you like to save everything from." },
];

export {
    whyDetails
};