import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function CreateContent() {
    return (
        <>
            <Card className="create-content-area  padding-bottom-110">
                <Container>
                    <Row>
                        <Col xl={4} lg={6}>
                            <Card.Body className="create-content-wrap">
                                <Card className="section-title startup padding-bottom-25">
                                    <h4 className="title">Start a register membership business </h4>
                                    <Card.Text>Inspiration comes in many ways and you like to save everything from. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Card.Text>
                                    <Card.Body className="btn-wrapper padding-top-30">
                                        <Card.Footer className="btn-startup boxed-btn reverse-color">Start Your 7 Days Free Trial</Card.Footer>
                                    </Card.Body>
                                </Card>
                            </Card.Body>
                        </Col>
                        <Col xl={{ span: 6, offset: 2 }} lg={6}>
                            <div className="trip-img wow animate__animated animate__backInDown bg-image" data-parallax="{&quot;x&quot;: -100, &quot;y&quot;: 0}" style={{ backgroundImage: 'url(assets/img/register/01.png)' }} />
                        </Col>
                    </Row>
                </Container>
            </Card >
        </>
    )
}

export default CreateContent