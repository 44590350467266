import React, { useEffect, useState } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Breadcrumb from './Breadcrumb';
import GetInTouch from './GetInTouch';
import HeaderBottom from './HeaderBottom';
import Testimonial from './Testimonial';
import OrganizationsHeader from './OrganizationsHeader';
import QualityWorks from './QualityWorks';
import HowITwork from './HowITwork';
import LoadingIndicator from '../../../LoadingIndicator';


function HowWorks() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate some asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 900);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Header />
          <>
            <Breadcrumb />
            <HowITwork />
            <QualityWorks />
            <OrganizationsHeader />
            <Testimonial />
            <HeaderBottom />
            <GetInTouch />
          </>
          <Footer />
        </>
      )}
    </>
  )
}

export default HowWorks