import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function OrganizationsHeader() {
    return (
        <>
            <Card className='organizations-header-section margin-bottom-30 rounded-0'>
                <Container>
                    <Card.Body className='organizations-header-wrap margin-bottom-30 bg-image' style={{ backgroundImage: 'url(../assets/img/map/map.png)' }}>
                        <Row className='justify-content-center'>
                            <Col lg={8}>
                                <Card.Title className='section-title brand white desktop-center'>
                                    <h3 className="title">We helped 400+ organizations</h3>
                                    <Card.Text>Each time a digital asset is purchased or sold, Sequoir donates a percentage of the fees</Card.Text>
                                    <span>Discover &amp; Get Support From Our Team</span>
                                </Card.Title>
                            </Col>
                        </Row>
                    </Card.Body>
                </Container>
            </Card>
        </>
    )
}

export default OrganizationsHeader