import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout';
import Home01 from '../components/userLayout/Home01/Home01';
import Home02 from '../components/userLayout/Home02/Home02';
import Home03 from '../components/userLayout/Home03/Home03';
import About from '../components/userLayout/Pages/About/About';
import ComingSoon from '../components/userLayout/Pages/ComingSoon/ComingSoon';
import Contact from '../components/userLayout/Pages/ContactUs/Contact';
import Faq from '../components/userLayout/Pages/Faq/Faq';
import HowWorks from '../components/userLayout/Pages/HowWorks/HowWorks';
import Service from '../components/userLayout/Pages/Service/Service';
import './App.css';

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home01 />} />
            <Route path='/index-2' element={<Home02 />} />
            <Route path='/index-3' element={<Home03 />} />
            <Route path='/pages/about' element={<About />} />
            <Route path='/pages/contact' element={<Contact />} />
            <Route path='/pages/service' element={<Service />} />
            <Route path='/pages/how-it-work' element={<HowWorks />} />
            <Route path='/pages/faq' element={<Faq />} />
            <Route path='/pages/coming-soon' element={<ComingSoon />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
