import React from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { faqDetail } from '../../../data/home02/faq';

const FaqDetail = (props) => {
    const { question, answer } = props;
    return (
        <Card.Body className="faq-single-item">
            <Card.Header className='content'>
                <h3 className="title">{question}</h3>
                <Card.Text>{answer}</Card.Text>
            </Card.Header>
        </Card.Body>
    );
};

function Faq() {
    return (
        <>
            <Card id="faq" className='frequently-area padding-bottom-70'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={6}>
                            <Card.Body className='section-title desktop-center margin-bottom-55'>
                                <h3 className="title social-title">Frequently asked quetions</h3>
                                <Card.Text>Inspiration comes in many ways and you like to save everything from.</Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            {faqDetail.map(data => <FaqDetail key={`faqDetail-${data.id}`} {...data} />)}
                        </Col>
                        <Col lg={6}>
                            <Card.Body className="faq-single-item">
                                <Card.Header className='content'>
                                    <h3 className="title">How can i install</h3>
                                    <Card.Text>Inspiration comes in many ways and you like to save everything from Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit</Card.Text>
                                </Card.Header>
                            </Card.Body>
                            <Card className="question-form-area">
                                <Card.Header className="header-content">
                                    <h4 className="title">Have any quetions?</h4>
                                    <Card.Text>Send your quetions in our mail</Card.Text>
                                </Card.Header>
                                <Form className="question-form" action="#">
                                    <Form.Group className="form-group">
                                        <Form.Control type="text" placeholder="Enter your email" />
                                    </Form.Group>
                                    <Button type="submit" className="submit-btn">Submit Now</Button>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Faq