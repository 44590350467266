import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { headerbottomDetails } from '../../../../data/pages/headerbottom';
import OwlCarousel from 'react-owl-carousel';

const HeaderBottomDetail = (props) => {
    const { image } = props;
    return (
        <Card className='single-brand'>
            <Card.Link href="#"><Image src={image} /></Card.Link>
        </Card>
    );
};

const options = {
    loop: true,
    items: 4,
    autoplay:true,
    nav: true,
    margin: 100,
    dots: false,
    navText: ['<span data-icon="&#x23;"></span>', '<span data-icon="&#x24;"></span>'],
    responsive: {
        0: {
            items: 2
        },
        600: {
            items: 3
        },
        992: {
            items: 4
        },
        1200: {
            items: 4
        }
    }
};

function HeaderBottom() {
    return (
        <>
            <Card className='header-bottom-area padding-bottom-85 padding-top-110 rounded-0'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={10}>
                            <Card.Body className='client-area'>
                                <OwlCarousel {...options}>
                                {headerbottomDetails.map(data => <HeaderBottomDetail key={`headerbottom-${data.id}`} {...data} />)}
                                </OwlCarousel>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default HeaderBottom