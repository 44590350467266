import React, { useEffect, useState } from 'react';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import Breadcrumb from './Breadcrumb';
import BuildArea from './BuildArea';
import CreativeTeam from './CreativeTeam';
import GetInTouch from './GetInTouch';
import HeaderBottom from './HeaderBottom';
import Organizations from './Organizations';
import OrganizationsHeader from './OrganizationsHeader';
import Testimonial from './Testimonial';
import UniqueProject from './UniqueProject';
import LoadingIndicator from '../../../LoadingIndicator';


function About() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate some asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Header />
          <>
            <Breadcrumb />
            <UniqueProject />
            <BuildArea />
            <CreativeTeam />
            <OrganizationsHeader />
            <Organizations />
            <Testimonial />
            <HeaderBottom />
            <GetInTouch />
          </>
          <Footer />
        </>
      )}
    </>
  )
}

export default About