
const contentDetails = [
    { id: 1, strongcontent: ' Inspiration comes', content: ' in many ways and you like to save' },
    { id: 2, strongcontent: ' Plan and schedule', content: ' your Instagram Stories on web or mobile' },
    { id: 3, strongcontent: ' Include a first comment', content: ' when you schedule Instagram posts' },
    { id: 4, strongcontent: ' Build custom reports', content: ' in many ways and you like to save' },
];

export {
    contentDetails
};