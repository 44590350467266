import React from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';


function ContactForm() {
    return (
        <>
            <Card className='contact-inner-area padding-bottom-90 rounded-0'>
                <Container>
                    <Row>
                        <Col lg={3}>
                            <Card.Body className='content-area'>
                                <Card.Header className='section-title padding-bottom-25'>
                                    <h4 className="title">Let’s scale your brand, together</h4>
                                </Card.Header>
                                <Card className='single-contact-item-02'>
                                    <Card.Header className='icon'>
                                        <i className="flaticon-call"></i>
                                    </Card.Header>
                                    <Card.Body className='content'>
                                        <Card.Text className="details">+458 123 657</Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card className='single-contact-item-02'>
                                    <Card.Header className='icon'>
                                        <i className="flaticon-email"></i>
                                    </Card.Header>
                                    <Card.Body className='content'>
                                        <Card.Text className="details">
                                            <Card.Link href="#">info@digi.com</Card.Link>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card className='single-contact-item-02'>
                                    <Card.Header className='icon'>
                                        <i className="flaticon-pin"></i>
                                    </Card.Header>
                                    <Card.Body className='content'>
                                        <Card.Text className="details">United Kingdom</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Card.Body>
                        </Col>
                        <Col lg={{ span: 6, offset: 3 }}>
                            <Card.Body className='contact-form style-01'>
                                <Form action="#" id="contact_page_form" className="contact-page-form" method="post">
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className='form-group'>
                                                <Form.Label>Name <span>*</span></Form.Label>
                                                <Form.Control type="text" name="name" placeholder="Type Name" aria-required="true" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className='form-group'>
                                                <Form.Label>Phone <span>*</span></Form.Label>
                                                <Form.Control type="text" name="phone" placeholder="Type Phone Number" aria-required="true" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className='form-group'>
                                                <Form.Label>Email Address <span>*</span></Form.Label>
                                                <Form.Control type="text" name="email" placeholder="Type Email Address" aria-required="true" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className='form-group'>
                                                <Form.Label>How can we help? <span>*</span></Form.Label>
                                                <Form.Control as="textarea" cols={1} rows={4} name="message" id="msg" placeholder="Type Desciption" aria-required="true" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className='form-group'>
                                                <Form.Control as="input" type="submit" value="Send Message" className="submit-btn" style={{width:'inherit'}}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default ContactForm