import React, { useEffect, useState } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Breadcrumb from './Breadcrumb';
import GetInTouch from './GetInTouch';
import HeaderBottom from '../About/HeaderBottom';
import CreativeTeam from './CreativeTeam';
import OrganizationsHeader from './OrganizationsHeader';
import CounterUp from './CounterUp';
import Work from './Work';
import LoadingIndicator from '../../../LoadingIndicator';


function Service() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate some asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Header />
          <>
            <Breadcrumb />
            <Work />
            <CounterUp />
            <OrganizationsHeader />
            <CreativeTeam />
            <HeaderBottom />
            <GetInTouch />
          </>
          <Footer />
        </>
      )}
    </>
  )
}

export default Service