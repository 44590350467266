import React from 'react';
import { Card, Col, Container, Image, ListGroup, Row, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { creativeteamDetails } from '../../../../data/pages/creativeteam';

const CreativeteamDetails = (props) => {
    const { image, title,profession } = props;
    return (
        <Col lg={3} sm={6}>
            <Card.Body className="team-single-item">
                <Card.Header className="thumb">
                    <Image src={image} alt="team-pic" />
                </Card.Header>
                <Card.Footer className="content">
                    <h4 className="title">{title}</h4>
                    <span>{profession}</span>
                    <div className="social-link style-02">
                        <ListGroup as="ul">
                            <ListGroup.Item as="li">
                                <Link to="#"><i className="fab fa-facebook-f" /></Link>
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                                <Link to="#"><i className="fab fa-linkedin-in" /></Link>
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                                <Link to="#"><i className="fab fa-twitter" /></Link>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </Card.Footer>
            </Card.Body>
        </Col>
    );
};

function CreativeTeam() {
    return (
        <>
            <Card className='creative-team padding-top-110 padding-bottom-85 rounded-0'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={8}>
                            <Card.Body className='section-title desktop-center padding-bottom-50'>
                                <span className="subtitle">Meet our team</span>
                                <h2 className="title">We glow all the way up</h2>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        {creativeteamDetails.map(data => <CreativeteamDetails key={`creativeteam-${data.id}`} {...data} />)}
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default CreativeTeam