import React from 'react';
import { Button, Card, Col, Container, Form, Image, ListGroup, Row } from 'react-bootstrap';

function Footer() {

    return (
        <>
            <footer className="footer-area">
                <Card className="footer-top bg-black bg-image padding-top-85 padding-bottom-50 rounded-0">
                    <Container>
                        <Row className="footer-top-border padding-bottom-30">
                            <Col lg={3} md={4}>
                                <Card.Body className="footer-widget widget">
                                    <div className="about_us_widget">
                                        <Card.Link href="/#" className="footer-logo" style={{width:'200px'}}> <Image src="assets/img/logo-02.png" alt="footer logo" /></Card.Link>
                                        <Card.Text>Inspiration comes in many ways and you like to save everything from. sed do eiusmod tempor incididunt .</Card.Text>
                                    </div>
                                </Card.Body>
                            </Col>
                            <Col lg={{ span: 8, offset: 1 }} md={8}>
                                <Card.Body className="footer-widget widget widget_subscribe">
                                    <div className="header-content">
                                        <h4 className="title">Newsletter</h4>
                                        <Card.Text>Get a bi-weekly digest of great articles</Card.Text>
                                    </div>
                                    <Form className="subscribe-form" action="#">
                                        <Form.Group>
                                            <Form.Control type="text" id="emailText" placeholder="Your email address" />
                                        </Form.Group>
                                        <Button type="submit" className="submit-btn">
                                            <i className="flaticon-send" />
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3} md={6} sm={6}>
                                <Card.Body className='footer-widget widget widget_nav_menu'>
                                    <h4 className="widget-title">Home Page</h4>
                                    <ListGroup as="ul" id="listGroupUl">
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Home</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>About</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Causes</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Site Map</Card.Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Col>
                            <Col lg={2} md={6} sm={6}>
                                <Card.Body className='footer-widget widget widget_nav_menu'>
                                    <h4 className="widget-title">inner Page</h4>
                                    <ListGroup as="ul" id="listGroupUl">
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Home</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>About</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Causes</Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'>Site Map</Card.Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Col>
                            <Col lg={4} md={6} sm={6}>
                                <Card.Body className='footer-widget widget widget_nav_menu'>
                                    <h4 className="widget-title">Lates Blog</h4>
                                    <ListGroup as="ul" id="listGroupUl" className='recent_post_item'>
                                        <ListGroup.Item as="li" className='single-recent-post-item'>
                                            <Card.Header className="thumb" as="div">
                                                <Card.Img src={'assets/img/popular-post/01.jpg'} alt="recent post"></Card.Img>
                                            </Card.Header>
                                            <Card.Footer className="content">
                                                <h4 className="title">
                                                    <Card.Link href="#">Cupidatat non proident sunt culpa officia</Card.Link>
                                                </h4>
                                                <span className="time"><i className="far fa-calendar-alt"></i>05 Oct 2020</span>
                                            </Card.Footer>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li" className='single-recent-post-item'>
                                            <Card.Header className="thumb" as="div">
                                                <Card.Img src={'assets/img/popular-post/02.jpg'} alt="recent post"></Card.Img>
                                            </Card.Header>
                                            <Card.Footer className="content">
                                                <h4 className="title">
                                                    <Card.Link href="#">Cupidatat non proident sunt culpa officia</Card.Link>
                                                </h4>
                                                <span className="time"><i className="far fa-calendar-alt"></i>05 Oct 2020</span>
                                            </Card.Footer>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Col>
                            <Col lg={3} md={6} sm={6}>
                                <Card.Body className='footer-widget widget widget_nav_menu'>
                                    <h4 className="widget-title">Follow us on</h4>
                                    <ListGroup as="ul" id="listGroupUlfollow" className='social_share margin-top-20'>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'><i className="fab fa-linkedin-in"></i></Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'><i className="fab fa-facebook-f"></i></Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'><i className="fab fa-twitter"></i></Card.Link>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <Card.Link to='#'><i className="fab fa-twitter"></i></Card.Link>
                                        </ListGroup.Item>
                                    </ListGroup>
                                    <Card.Footer className="copyright-area-inner">
                                        © Copyrights 2023 CodeyesAll rights reserved.
                                    </Card.Footer>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            </footer>
        </>
    )
}

export default Footer