import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function CallToAction() {
    return (
        <>
            <Card className="call-to-action-area">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Card.Body className="call-to-action-inner style-01">
                                <div className="bg-image" style={{ backgroundImage: 'url(assets/img/call-to-action/01.png)' }} />
                                <h2 className="title">Refer a friend &amp; enjoy 1 year free subscription </h2>
                                <Card.Header className="btn-wrapper paddinginitial">
                                    <Card.Link href="#" className="boxed-btn btn-startup">Refer A Friend</Card.Link>
                                </Card.Header>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default CallToAction