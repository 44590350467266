import React, { useEffect, useState } from 'react';
import CallToAction from './CallToAction';
import Footer from './Common/Footer';
import Header from './Common/Header';
import CreateContent from './CreateContent';
import Faq from './Faq';
import HeaderBottom from './HeaderBottom';
import HeaderSlider from './HeaderSlider';
import Idea from './Idea';
import Offer from './Offer';
import OfferItem from './OfferItem';
import Overview from './Overview';
import Priceplan from './Priceplan';
import Testimonial from './Testimonial';
import LoadingIndicator from '../../LoadingIndicator';


const Home = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate some asynchronous operation
        setTimeout(() => {
            setIsLoading(false);
        }, 600);
    }, []);

    return (
        <>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <Header />
                    <>
                        <HeaderSlider />
                        <HeaderBottom />
                        <Idea />
                        <Overview />
                        <CreateContent />
                        <OfferItem />
                        <Offer />
                        <Testimonial />
                        <Priceplan />
                        <Faq />
                        <CallToAction />
                    </>
                    <Footer />
                </>
            )}
        </>
    )
}

export default Home

