
const howitworkDetails = [
    { id: 1, classname: '', icon: 'far fa-eye', title: 'Eyes on the Ground', description: "Dozens of leading utility providers like" },
    { id: 2, classname: 'style-01', icon: 'fas fa-retweet', title: 'Rapid, Informed Response', description: "Dozens of leading utility providers like National Grid are gaining enhanced" },
    { id: 3, classname: 'style-02', icon: 'flaticon-monitor', title: 'Contextual Interface', description: "Dozens of leading utility providers like National Grid are gaining enhanced" },
    { id: 4, classname: 'style-03', icon: 'fas fa-list', title: 'Streamlined Reporting', description: "Dozens of leading utility providers like National Grid are gaining enhanced" },
];

const howitworkBannerDetails = [
    { id: 1, classname: 'style-01', title: 'Create Account', description: "Each time a digital asset is purchased or sold, Sequoir donates a percentage of the fees back" },
    { id: 2, classname: '', title: 'Fill The Form', description: "Each time a digital asset is purchased or sold, Sequoir donates a percentage of the fees back" },
    { id: 3, classname: 'style-02', title: 'Lets Enjoy', description: "Each time a digital asset is purchased or sold, Sequoir donates a percentage of the fees back" },
];

export {
    howitworkDetails,
    howitworkBannerDetails
};