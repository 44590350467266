import React from 'react';
import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap';

function Overview() {
    return (
        <>
            <Card id="overview" className="create-content-area padding-bottom-90 padding-top-75">
                <Container>
                    <Row>
                        <Col lg={7}>
                            <div className="trip-img wow animate__animated animate__backInUp bg-image" data-parallax="{&quot;x&quot;: 100, &quot;y&quot;: 0}" style={{ backgroundImage: 'url(assets/img/bg/startup/01.png)' }} />
                        </Col>
                        <Col xl={{ span: 4, offset: 1 }} lg={5}>
                            <Card.Body className="create-content-wrap">
                                <Card className="section-title startup padding-bottom-25">
                                    <h4 className="title">Never miss a task, Get always step ahead.</h4>
                                    <Card.Text>Inspiration comes in many ways and you like to save everything from. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Card.Text>
                                    <ListGroup as="ul" className="content">
                                        <ListGroup.Item as="li"><i aria-hidden="true" className="fas fa-check" />Argument sounds very appealing.</ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <i aria-hidden="true" className="fas fa-check" />Many predicted that the saviour of publishing.
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li">
                                            <i aria-hidden="true" className="fas fa-check" />Easily connect any existing mailing list
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Overview