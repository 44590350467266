import React from 'react';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';

function Destination() {
  return (
    <>
      <Card id="destination" className="destination-area padding-bottom-90 padding-top-115">
        <Container>
          <Row>
            <Col lg={5}>
              <div className="destination-img wow animate__animated animate__backInUp bg-image" data-parallax="{&quot;x&quot;: 100, &quot;y&quot;: 0}" style={{ backgroundImage: 'url(assets/img/bg/01.png)' }}></div>
            </Col>
            <Col lg={{ span: 6, offset: 1 }}>
              <Card.Title className="section-title padding-bottom-25">
                <Badge className="sub-title" bg="inherit">Select Destination</Badge>
                <h4 className="title">Choose a <Badge bg="inherit">destination</Badge> where you want to <Badge bg="inherit">travel</Badge> </h4>
                <Card.Text>Inspiration comes in many ways and you like to save everything from. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</Card.Text>
              </Card.Title>
              <Row>
                <Col lg={6} md={6} sm={6}>
                  <Card.Body className='destination-single-item margin-bottom-30'>
                    <Card.Header className='icon'>
                      <i className="flaticon-hotel"></i>
                    </Card.Header>
                    <Card.Footer className='content'>
                      <h4 className="title">
                        <Card.Link href='#'>Great Deal</Card.Link>
                      </h4>
                      <Card.Text>Inspiration comes in many ways</Card.Text>
                    </Card.Footer>
                  </Card.Body>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <Card.Body className='destination-single-item margin-bottom-30'>
                    <Card.Header className='icon  style-01'>
                      <i className="flaticon-notification"></i>
                    </Card.Header>
                    <Card.Footer className='content'>
                      <h4 className="title">
                        <Card.Link href='#'>Find Transport</Card.Link>
                      </h4>
                      <Card.Text>Inspiration comes in many ways</Card.Text>
                    </Card.Footer>
                  </Card.Body>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  )
}

export default Destination