import React from 'react';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';
import { whyDetails } from '../../../data/home01/whyDetails';

const WhyDetail = (props) => {
  const { icon, description, title, classname } = props;
  return (
    <Col lg={6} md={6} sm={6}>
      <Card.Body className="choose-single-item margin-bottom-30">
        <Card.Header className={"icon paddinginitial " + classname}>
          <i className={icon}></i>
        </Card.Header>
        <Card.Footer className="content">
          <h4 className="title">
            <Card.Link href='#'>{title}</Card.Link>
          </h4>
          <Card.Text>{description}</Card.Text>
        </Card.Footer>
      </Card.Body>
    </Col>
  );
};

function ChooseUs() {
  return (
    <>
      <Card id="why" className="choose-us-area bg-image padding-top-90" style={{ backgroundImage: 'url(assets/img/choose/bg.png)' }}>
        <div className="bg-img" style={{ backgroundImage: 'url(assets/img/choose/01.png)' }} />
        <div className="bg-img-02" style={{ backgroundImage: 'url(assets/img/leaf/leaf-01.png)' }} />
        <div className="bg-img-03" style={{ backgroundImage: 'url(assets/img/leaf/leaf-02.png)' }} />
        <Container>
          <Row>
            <Col lg={5}>
              <Card.Title className="section-title padding-bottom-25">
                <Badge className="sub-title" bg="inherit">Why Choose Us</Badge>
                <h4 className="title">We are <Badge bg="inherit">best travel agency</Badge> with <Badge bg="inherit">100% satisfaction</Badge> services </h4>
              </Card.Title>
            </Col>
            <Col lg={{ span: 6, offset: 1 }}>
              <Row>
                {whyDetails.map(data => <WhyDetail key={`why-${data.id}`} {...data} />)}
              </Row>
            </Col>
          </Row>
        </Container>
        <Card.Body className='call-to-action-area padding-top-80 bg-image'>
          <Container>
            <Row>
              <Col lg={12} md={12}>
                <Card className="call-to-action-inner">
                  <Card.Header className="line-area">
                    <span className="line-one" />
                    <span className="line-two" />
                    <span className="line-three" />
                  </Card.Header>
                  <span className="bubble" />
                  <div className="action-bg-img" style={{ backgroundImage: 'url(assets/img/call-to-action/animation/dot.png)' }} />
                  <div className="action-bg-img-02" style={{ backgroundImage: 'url(assets/img/call-to-action/animation/cricle.png)' }} />
                  <h2 className="title">Sign up to join with us</h2>
                  <Card.Text className="subtitle">Inspiration comes in many ways and you like to save everything from.</Card.Text>
                  <Card.Footer className="btn-wrapper desktop-center">
                    <Card.Link href="#" className="boxed-btn">Send App to Your Phone</Card.Link>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  )
}

export default ChooseUs