import React from 'react';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';

function Trip() {
  return (
    <>
      <Card id="trip" className="trip-area padding-bottom-120">
        <Container>
          <Row>
            <Col lg={5}>
              <Card.Title className="section-title padding-bottom-25">
                <Badge className="sub-title" bg="inherit">Book A Trip</Badge>
                <h4 className="title">Then <Badge bg="inherit">book your trip</Badge> from our exclusive <Badge bg="inherit">offers</Badge>. </h4>
                <Card.Text>Inspiration comes in many ways and you like to save everything from. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</Card.Text>
              </Card.Title>
              <Card.Body className='content'>
                <h3 className="sub-title">Take your flight </h3>
                <Card.Text>in many ways and you like to save Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</Card.Text>
                <h3 className="sub-title">Room Service </h3>
                <Card.Text>in many ways and you like to save Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</Card.Text>
              </Card.Body>
            </Col>
            <Col lg={{ span: 6, offset: 1 }}>
              <div className="trip-img wow animate__animated animate__backInDown bg-image" data-parallax="{&quot;x&quot;: -100, &quot;y&quot;: 0}" style={{ backgroundImage: 'url(assets/img/bg/02.png)' }}></div>
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  )
}

export default Trip