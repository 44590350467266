import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function Contactarea() {
    return (
        <>
            <Card id="what" className="contact-area padding-bottom-350 bg-image rounded-0" style={{ backgroundImage: 'url(../assets/img/count-down/bg.png)' }}>
                <Container>
                    <Row>
                        <Col lg={4} md={6}>
                            <Card.Body className="single-icon-box-05">
                                <Card.Header className="icon">
                                    <i className="flaticon-call" />
                                </Card.Header>
                                <div className="content">
                                    <h3 className="title">Call Us</h3>
                                    <Card.Text>666 - 111 - 000 <br /> 236 - 671 - 342 </Card.Text>
                                </div>
                            </Card.Body>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card.Body className="single-icon-box-05">
                                <Card.Header className="icon">
                                    <i className="flaticon-email" />
                                </Card.Header>
                                <div className="content">
                                    <h3 className="title">Mail Us</h3>
                                    <Card.Text>
                                        <Card.Link href="#">info@digi.com <br/> info@digi.com</Card.Link>
                                    </Card.Text>
                                </div>
                            </Card.Body>
                        </Col>
                        <Col lg={4} md={6}>
                            <Card.Body className="single-icon-box-05">
                                <Card.Header className="icon">
                                    <i className="flaticon-pin" />
                                </Card.Header>
                                <div className="content">
                                    <h3 className="title">Location</h3>
                                    <Card.Text>London, <br /> United Kingdom </Card.Text>
                                </div>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default Contactarea