import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import { headerbottomDetails } from '../../../data/home02/headerbottom';

const HeaderBottomDetail = (props) => {
    const { image } = props;
    return (
        <Card className='single-brand'>
            <Card.Link href="#"><Image src={image} /></Card.Link>
        </Card>
    );
};

const options = {
    loop: true,
    items: 4,
    autoplay: true,
    nav: true,
    margin: 100,
    dots: false,
    navText: ['<span data-icon="&#x23;"></span>', '<span data-icon="&#x24;"></span>'],
    responsive: {
        0: {
            items: 2
        },
        600: {
            items: 3
        },
        992: {
            items: 4
        },
        1200: {
            items: 4
        }
    }
};

function HeaderBottom() {
    return (
        <>
            <div className="header-bottom-img m-top wow animate__animated animate__fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s" data-wow-offset="60" data-parallax="{&quot;x&quot;: 20, &quot;y&quot;: 50}" style={{ backgroundImage: 'url(assets/img/bg/social/03.png)', zIndex: 3 }} />
            <Card className="header-bottom-area padding-bottom-85 padding-top-110">
                <div className="triangle" data-parallax="{&quot;x&quot;: -50, &quot;y&quot;: 50}" />
                <div className="half-cricle" />
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <Card.Body className='section-title desktop-center padding-bottom-40'>
                                <h3 className="title social-title">Over 20k+ Teams using our app for communications</h3>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <Card.Body className='client-area'>
                                <OwlCarousel {...options}>
                                    {headerbottomDetails.map(data => <HeaderBottomDetail key={`headerbottom-${data.id}`} {...data} />)}
                                </OwlCarousel>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default HeaderBottom