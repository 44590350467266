import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Tilt } from 'react-tilt';

const defaultOptions = {
  max: 80,
  speed: 400,
  perspective: 200,
  scale: 1.2,
  reverse: true,
}

function HeaderSlider() {
  return (
    <>
      <Card className='header-area header-social header-bg' style={{ backgroundImage: 'url(assets/img/header-slider/social/bg.png)' }}>
        <div className="social-bg-img outer" data-parallax="{&quot;x&quot;: 220, &quot;y&quot;: 150}" >
          <Tilt options={defaultOptions}>
            <Image className='parallax-image' src='assets/img/header-slider/social/01.png' />
          </Tilt>
        </div>
        <div className="social-bg-img-02 outer" data-parallax="{&quot;x&quot;: 20, &quot;y&quot;: 150}" >
          <Tilt options={defaultOptions}>
            <Image className='parallax-image2' src='assets/img/header-slider/social/02.png' />
          </Tilt>
        </div>
        <div className="social-bg-img-03 outer" data-parallax="{&quot;x&quot;: 20, &quot;y&quot;: 150}" >
          <Tilt options={defaultOptions}>
            <Image className='parallax-image3' src='assets/img/header-slider/social/03.png' />
          </Tilt>
        </div>
        <div className="social-bg-img-04 outer" data-parallax="{&quot;x&quot;: 20, &quot;y&quot;: 150}" >
          <Tilt options={defaultOptions}>
            <Image className='parallax-image4' src='assets/img/header-slider/social/04.png' />
          </Tilt>
        </div>
        <Container>
          <Row className='justify-content-center'>
            <Col lg={7}>
              <Card.Body className="header-inner desktop-center">
                <h1 className="title">A Better way to share on social media</h1>
                <Card.Text>Inspiration comes in many ways and you like to save .</Card.Text>
                <Card.Header className="btn-wrapper desktop-center padding-top-30">
                  <Card.Link href="#" className="boxed-btn btn-gradient">Start 7 Days Free Trials</Card.Link>
                </Card.Header>
              </Card.Body>
            </Col>
          </Row>
        </Container>
      </Card>
    </>

  )
}

export default HeaderSlider