
const headerbottomDetails = [
    { id: 1, image: '../assets/img/client/01.png' },
    { id: 2, image: '../assets/img/client/02.png' },
    { id: 3, image: '../assets/img/client/03.png' },
    { id: 4, image: '../assets/img/client/04.png' },
    { id: 5, image: '../assets/img/client/03.png' },
];

export {
    headerbottomDetails
};