import React from 'react';
import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

function CounterUp() {
    return (
        <>
            <Card className='counterup-area padding-bottom-105 padding-top-120 rounded-0'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={8}>
                            <Card.Body className='section-title desktop-center padding-bottom-50'>
                                <h2 className="title">Train you on best practices we’ve picked up from other enterprise customers. </h2>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <ListGroup as='ul' className="counter-item-list">
                                <ListGroup.Item as='li' className="single-counterup-01">
                                    <Card.Body className="content">
                                        <div className="count-wrap">
                                            <CountUp start={0.00} end={4.81} duration={2.75} decimal={'.'} decimals={2} redraw={true}>
                                                {({ countUpRef, start }) => (
                                                    <VisibilitySensor onChange={start} delayedCall>
                                                        <span ref={countUpRef} className="count-num"></span>
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>
                                        </div>
                                        <h4 className="title">User Reviews</h4>
                                    </Card.Body>
                                </ListGroup.Item>
                                <ListGroup.Item as='li' className="single-counterup-01">
                                    <Card.Body className="content">
                                        <div className="count-wrap">
                                            <CountUp end={33629} redraw={true}>
                                                {({ countUpRef, start }) => (
                                                    <VisibilitySensor onChange={start} delayedCall>
                                                        <span ref={countUpRef} className="count-num"></span>
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>
                                        </div>
                                        <h4 className="title">Trusted &amp; loved by users</h4>
                                    </Card.Body>
                                </ListGroup.Item>
                                <ListGroup.Item as='li' className="single-counterup-01">
                                    <Card.Body className="content">
                                        <div className="count-wrap">
                                            <CountUp end={107} redraw={true}>
                                                {({ countUpRef, start }) => (
                                                    <VisibilitySensor onChange={start} delayedCall>
                                                        <span ref={countUpRef} className="count-num"></span>
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>k+</div>
                                        <h4 className="title">Total Downloads</h4>
                                    </Card.Body>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    )
}

export default CounterUp